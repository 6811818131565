<template>
  <div>
    <v-dialog v-model="dialog" width="748px">
      <template v-slot:activator="{ on, attrs }">
        <span class="export-trade-errors" v-bind="attrs" v-on="on">Exportar log de erros</span>
      </template>

      <v-card style="overflow-x:hidden">
        <v-card-title>
          Exportar log de erros
        </v-card-title>

        <v-card-subtitle>
          Um relatório contendo todos os erros de todas as campanhas será enviado para os e-mails listados abaixo
        </v-card-subtitle>

        <v-card-text class="mt-2">
          <span>Escolha o formato do arquivo a ser exportado:</span>
          <v-radio-group v-model="fileFormat" row>
            <v-radio label="Formato .csv" value="CSV"></v-radio>
            <v-radio label="Formato .xlsx" value="XLSX"></v-radio>
          </v-radio-group>

          <include-emails-table ref="includeEmailsTable" :emails.sync="emails" :max-emails-to-scroll="4" scroll-height="192px" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <mf-button label="Fechar" outlined color="error" @click="() => (dialog = false)" />
          <mf-action-buttons
            class="ml-2"
            :primary-button="{
              text: 'Exportar',
              action: exportLog,
              isDisabled: emails.length <= 0,
              isVisible: true,
              isLoading: loading
            }"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IncludeEmailsTable from './IncludeEmailsTable.vue'
import { MUTATION_TRADE_ERRORS_REPORT } from '@/modules/industry/graphql'

export default {
  components: {
    IncludeEmailsTable
  },
  props: {
    tradeType: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    emails: [],
    fileFormat: 'CSV'
  }),
  methods: {
    async exportLog() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TRADE_ERRORS_REPORT,
          variables: {
            emails: this.emails,
            tradeType: this.tradeType,
            fileFormat: this.fileFormat
          },
          context: {
            uri: this.$microservicesUrls['asyncJobs']
          }
        })
        this.$snackbar({ message: 'Arquivo enviado por e-mail', snackbarColor: 'success' })
        this.dialog = false
      } catch {
        this.$snackbar({ message: 'Erro ao requisitar log de erros', snackbarColor: 'error' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.export-trade-errors {
  font-size: 14px;
  color: #0062ff;
  user-select: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
